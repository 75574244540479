import { VID } from "../../../../../project-config";
import { POST_PERSONALIZED_QUESTIONS } from "../../../../../redux/links";

const postPersonalizedQuestion = (
  productId = null,
  inputs,
  dispatchCallBack,
  updatePersonalizedInfo = false
) => {
  let form = new FormData();
  form.append("disablePageCount", "0");
  form.append("_targetaddItems", `basket.html?vid=${VID}`);
  form.append(
    "mode",
    updatePersonalizedInfo ? "updatePersonalizedInfo" : "addItems"
  );

  Object.keys(inputs).forEach(key => {
    const input = inputs[key];
    if (input !== null) {
      if (Array.isArray(input)) {
        input.forEach((v, i) =>
          form.append(`answer${i !== 0 ? i + 1 : ""}_${key}`, v)
        );
      } else form.append(`answer_${key}`, input);
    }
  });
  form.append("basketPage", "Submit");

  fetch(POST_PERSONALIZED_QUESTIONS(productId), {
    method: "POST",
    body: form,
    headers: {
      Accept: "*/*",
      credentials: "same-origin"
    },
    mimeType: "multipart/form-data",
    data: form
  })
    .then(res => res.status)
    .then(status => dispatchCallBack(status))
    .catch(err =>
      console.info("ERROR: failed posting personalized questions", err)
    );
};

export default postPersonalizedQuestion;
