import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import classes from "./TextInputField.module.css";

export default function TextInputField({
  readOnly,
  validationFailed,
  validations,
  setValidations,
  state,
  setState,
  field,
  label,
  placeHolder,
  lines,
  addNewLine,
  addNewLineText,
  multiLine,
  softwareEmailProof,
  buttonRef,
  lastInputRef,
  firstInputRef,
  progressState
}) {
  const [newLineClicked, setNewLineClicked] = useState(false);
  const [emailProofChecked, setEmailProofChecked] = useState(false);
  useLayoutEffect(() => {
    if (firstInputRef && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    let validations = {};
    if (lines.length > 0) {
      let inputs = lines.reduce((a, c) => {
        if (c && c.validations) {
          console.info("validations2", lines, c, field);
          if (validations.hasOwnProperty(field) === false) {
            validations[field] = {};
          }
          // set active if setActiveOnMount
          if (c.validations.setActiveOnMount) {
            c.validations.active = true;
          }

          validations[field][c.id] = c.validations;
        }
        a[c.id] = c.answer || c.defaultValue || "";
        return a;
      }, {});
      setState(inputs);
    } else {
      setState({
        [field]: ""
      });
    }
    console.info("validations3", validations);

    setValidations(validations);

    return () => {
      setValidations({ [field]: null });
      setState({
        [field]: ""
      });
    };
  }, []);

  const handleOnChange = e => {
    const { value, name } = e.target;
    setState({
      [name]: value
    });
  };

  const handleEmailProofChanged = () => {
    if (emailProofChecked) {
      setState({
        [field]: ""
      });
    }

    const foundValidation = validations && validations[field];
    if (foundValidation && lines.length > 0) {
      const foundValidationFirstDetailId =
        foundValidation && foundValidation[lines[0].id];
      if (foundValidationFirstDetailId.active !== undefined) {
        let setActiveValidation = { ...foundValidation };

        setActiveValidation[lines[0].id].active = !emailProofChecked;
        setValidations({ [field]: setActiveValidation });
      }
    }
    setEmailProofChecked(!emailProofChecked);
  };
  console.info("validations33", validations);

  const fillValidationMessages = validation => {
    let validationMessages = [];

    if (validation.validationType === "length") {
      let { min, max } = validation.validation;
      if (min !== undefined && max !== undefined) {
        validationMessages.push(`Must be between ${min} and ${max} in length`);
      } else if (min !== undefined) {
        validationMessages.push(`Must be at least ${min} in length`);
      } else if (max !== undefined) {
        validationMessages.push(`Must be less than ${max} in length`);
      }
    }
    if (validation.validationType === "digitsOnly") {
      validationMessages.push(`Can only be digits`);
    }

    if (validation.validationType === "type") {
      if (validation.validation === "email") {
        validationMessages.push("Not a valid email address");
      }

      if (validation.validation === "required") {
        validationMessages.push("Required");
      }
    }

    return validationMessages;
  };

  const renderInputs = () => {
    if (softwareEmailProof && lines.length > 0) {
      const line = lines[0];
      let validationMessages = [];

      console.info("failedValidations", line.failedValidations);
      if (validationFailed && line.failedValidations) {
        line.failedValidations.forEach(validation => {
          validationMessages = fillValidationMessages(validation);
        });
      }

      let decodedValue = "";

      return (
        <div className={classes.inputWrapper}>
          <label className={classes.title}>{label}</label>
          <input
            checked={emailProofChecked}
            onChange={handleEmailProofChanged}
            type="checkbox"
          ></input>
          {emailProofChecked && (
            <input
              className={`${
                validationMessages.length > 0
                  ? classes.inputValidationFailed
                  : ""
              }`}
              readOnly={(readOnly || line.defaultValue) && !line.ignoreReadOnly}
              value={
                state[line.id] &&
                state[line.id] !== "Not provided:  Leave blank"
                  ? decodedValue || state[line.id]
                  : ""
              }
              placeholder={placeHolder}
              onChange={handleOnChange}
              name={line.id}
            />
          )}
          {emailProofChecked &&
            validationMessages.map(msg => (
              <p className={classes.validationMessage}>{msg}</p>
            ))}
        </div>
      );
    }
    if (lines.length > 0) {
      return (
        <div className={classes.intputGroup}>
          <label className={classes.title}>{label}</label>

          {lines.map((line, index) => {
            let validationMessages = [];
            if (validationFailed && line.failedValidations) {
              console.info("failedValidations", line.failedValidations);
              line.failedValidations.forEach(validation => {
                validationMessages = fillValidationMessages(validation);
              });
            }
            return (
              <div id={line.id} key={line.id} className={classes.inputWrapper}>
                <label
                  for={line.id}
                  dangerouslySetInnerHTML={{ __html: line.label + ":" }}
                ></label>
                <input
                  className={`${
                    validationMessages.length > 0
                      ? classes.inputValidationFailed
                      : ""
                  }`}
                  readOnly={
                    (readOnly || line.defaultValue) && !line.ignoreReadOnly
                  }
                  value={
                    state[line.id] &&
                    state[line.id] !== "Not provided:  Leave blank"
                      ? state[line.id]
                      : ""
                  }
                  placeholder={placeHolder}
                  onChange={handleOnChange}
                  name={line.id}
                  id={line.id}
                  ref={
                    index === lines.length - 1 &&
                    line.linesLength.len - 1 === line.linesLength.ind &&
                    progressState.progress === 1
                      ? lastInputRef
                      : index === 0 && line.linesLength.ind === 0
                      ? firstInputRef
                      : null
                  }
                  onKeyDown={e => {
                    if (
                      index === lines.length - 1 &&
                      line.linesLength.len - 1 === line.linesLength.ind &&
                      e.keyCode === 9 &&
                      !e.shiftKey
                    ) {
                      e.preventDefault();
                      buttonRef.current.setAttribute("tag", true);
                      buttonRef.current.focus();
                    } else if (index === 1 && e.keyCode === 9) {
                      buttonRef.current.setAttribute("tag", false);
                    }
                  }}
                />
                {validationMessages.map(msg => (
                  <p className={classes.validationMessage}>{msg}</p>
                ))}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={classes.inputWrapper}>
          <label
            for={field}
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: label + ":" }}
          ></label>
          <input
            readOnly={readOnly}
            value={
              state[field] && state[field] !== "Not provided:  Leave blank"
                ? state[field]
                : ""
            }
            placeholder={placeHolder}
            onChange={handleOnChange}
            name={field}
            id={field}
          />
        </div>
      );
    }
  };

  return <div className={classes.wrapper}>{renderInputs()}</div>;
}
