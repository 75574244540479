import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

export const handleSelectedBackgroundColor = (
  selectedId,
  question,
  options,
  dispatch,
  progressState
) => {
  if (["backgroundColor"].includes(question) === false) return;
  console.info("selected ink", question);

  let selectedOption = options.find(opt => opt.detailID === selectedId);

  if (!selectedOption) {
    dispatch(
      setPersonalizedQuestionsProgressAction({
        background: {
          active: true,
          selectedColor: "notFound",
          imageExists: null
        }
      })
    );
  } else {
    let previewVal = selectedOption && selectedOption.previewVal;

    console.info("selected ink progress", progressState);
    dispatch(
      setPersonalizedQuestionsProgressAction({
        background: {
          active: true,
          selectedColor: previewVal,
          imageExists: null
        }
      })
    );
  }
};
